
export function env() {
    return {
        SERVICE_BASEURL: "./",
        // BASEURL: 'https://web.gopocket.in',
        // BASEURL: 'https://web.uat.gopocket.in',
        // BASEURL: import.meta.env.VITE_API_URL,
        EKYCBASEURL: `https://ekyc.gopocket.in`,
        endpoint: {
            position: `po-rest/positions`,
            order: `od-rest/info/orderbook`,
            trade: `od-rest/info/tradebook`,
            holdings:`ho-rest/holdings`
        },
        module: {
            ekyc: 'ekyc-rest',
            login: 'am',
            client: 'client-rest',
            watchlist: 'mw-rest',
            scrips: 'scrips-rest'
        },
        HTTP_method : {
            GET : 'get',
            POST: 'post',
            DELETE: 'delete',
            PUT:'put',
            PATCH: 'patch' 
        },
        CHARTURL: `https://web.gopocket.in/rest/ChartAPIService`,
        localUrl: 'http://localhost:8080/chartFrame/',
        // localUrl: 'http://127.0.0.1:8080/chartFrame/'
        depolyedUrl: "https://web.gopocket.in/chartFrame/",
        razerPayKey:'rzp_live_SZ6HSZtBNeMycO'
    }
}

